<template>
  <section
    id="p-tariffs"
    class="p-tariffs"
    data-test="tariffs-block"
  >
    <div class="container">
      <template v-if="!houseUrl">
        <h2 class="p-tariffs__title headline-2">
          Тарифы {{ provider.provider.name }} {{ getAddressText.cityOnlyLoc }}
        </h2>
        <p
          data-test="specify-address-tariffs-block"
          class="p-tariffs__subtitle title-5 gray-dark--text"
        >
          Тарифы по адресу могут отличаться от тарифов по городу.
          <button
            class="secondary-dark--text"
            @click="showDialogAddress"
          >
            Укажите адрес
          </button>
        </p>
      </template>
      <template v-else-if="inCity">
        <h2 class="p-tariffs__title headline-2">
          Тарифы {{ provider.provider.name }} {{ getAddressText.cityOnlyLoc }}
        </h2>
      </template>
      <h2
        v-else
        class="p-tariffs__secondtitle headline-2"
      >
        Тарифы и акции в вашем доме
      </h2>
      <div v-if="tariffs && tariffs.sortDebug">
        {{ sortDebug }}
      </div>

      <article
        class="p-tariffs__wrap d-flex w-100"
        :class="{ 'flex-column': !disabledPortal }"
      >
        <section class="flex-grow">
          <div class="p-tariffs__sf d-flex flex-wrap align-center">
            <div class="p-tariffs__sf-wrap d-flex align-center">
              <a
                :href="`/${currentCity?.url}/`"
                :data-test="`all-smart-filter`"
                class="text-no-wrap"
                :class="{
                  active: ($route.name === 'city-url' || $route.path === '/') && !filters.serviceSets?.length,
                }"
                @click.prevent="goTo(`/${currentCity?.url}/`)"
              >
                Все
              </a>
              <a
                v-for="link in btns"
                :key="link.id"
                :href="`/${currentCity?.url}/${link.url}/`"
                :data-test="`${link.url}-smart-filter`"
                class="text-no-wrap"
                :class="{
                  active:
                    $route.name.replace('city-', '') === link.url && !filters.serviceSets?.length
                    || link.setsId.join()
                      === filters.serviceSets.sort((a, b) => a - b).join(),
                }"
                @click.prevent="goTo(`/${currentCity?.url}/${link.url}/`)"
              >
                {{ link.name }}
              </a>
            </div>

            <LazyVMenu
              v-if="disabledPortal"
              content-class="white--bg"
            >
              <template #activator="{ props }">
                <p
                  v-bind="props"
                  class="p-tariffs__menu black--text subhead-3 pointer d-flex align-center"
                >
                  <img
                    loading="lazy"
                    src="@/assets/images/svg/sort_icon.svg"
                    class="d-flex"
                    alt="sort_icon"
                    style="margin-right: 4px;"
                    :class="{
                      'rotate-x':
                        filters.sort === 'EXPENSIVE'
                        || filters.sort === 'FAST',
                    }"
                  >
                  <span>
                    {{
                      currentSort
                    }} </span>
                </p>
              </template>
              <LazyVList elevation="4">
                <LazyVListItem
                  v-for="item in sortList"
                  :key="item.val"
                  :disabled="item.val === filters.sort"
                  @click="setSort(item.val)"
                >
                  <span class="subhead-3">{{ item.name }}</span>
                </LazyVListItem>
              </LazyVList>
            </LazyVMenu>
          </div>
          <template v-if="!disabledPortal">
            <div class="p-tariffs__mobile-filter d-flex jusftify-between">
              <LazyVMenu
                content-class="white--bg"
              >
                <template #activator="{ props }">
                  <p
                    v-bind="props"
                    class="p-tariffs__menu black--text title-5 pointer d-flex align-center"
                  >
                    <img
                      loading="lazy"
                      src="@/assets/images/svg/sort_icon.svg"
                      class="d-flex"
                      alt="sort_icon"
                      style="margin-right: 4px;"
                      :class="{
                        'rotate-x':
                          filters.sort === 'EXPENSIVE'
                          || filters.sort === 'FAST',
                      }"
                    >
                    <span>
                      {{
                        currentSort
                      }} </span>
                  </p>
                </template>
                <LazyVList elevation="4">
                  <LazyVListItem
                    v-for="item in sortList"
                    :key="item.val"
                    :disabled="item.val === filters.sort"
                    @click="setSort(item.val)"
                  >
                    <span class="subhead-3">{{ item.name }}</span>
                  </LazyVListItem>
                </LazyVList>
              </LazyVMenu>
              <button
                class="p-tariffs__all-filters title-5 d-flex align-center justify-center relative"
                @click="showFilters"
              >
                <img
                  loading="lazy"
                  src="@/assets/images/svg/filter.svg"
                  alt="filter"
                  class="d-flex"
                >
                Все фильтры
                <span
                  v-show="showClearBtn"
                  class="p-tariffs__all-filters-count d-flex align-center justify-center"
                />
              </button>
            </div>
            <div class="t-filters__filters">
              <div
                v-if="agg"
                class="p-tariffs__chips-wrap d-flex"
              >
                <div
                  v-for="tag in availableTags.filter(
                    (el) => filters[el.filter],
                  )"
                  :key="'chips_tag_' + tag.value"
                  :class="{
                    'active': filters[tag.filter],
                    'p-tariffs__chips-promo': tag.filter === 'promo',
                  }"
                  class="p-tariffs__chips d-flex align-center text-no-wrap"
                  @click="toggleChips(tag.filter)"
                >
                  {{ tag.name }}
                  <img
                    v-show="filters[tag.filter]"
                    loading="lazy"
                    src="@/assets/images/svg/chips-close_white.svg"
                    class="d-flex"
                    alt="close"
                    @click.stop="removeChips(tag.filter, false)"
                  >
                </div>
                <div
                  v-show="filters.subscriptionAny"
                  class="p-tariffs__chips subhead-3 d-flex align-center text-no-wrap active"
                  @click="toggleChips('subscriptionAny')"
                >
                  Онлайн-кинотеатры
                  <img
                    loading="lazy"
                    src="@/assets/images/svg/chips-close_white.svg"
                    class="d-flex"
                    alt="close"
                    @click.stop="removeChips('subscriptionAny', false)"
                  >
                </div>
                <div
                  v-show="
                    false
                      && ((filters.serviceSets && filters.serviceSets.length)
                        || (filters.serviceSetsContains
                          && filters.serviceSetsContains.length))
                      && !filters.smartFilter
                  "
                  class="p-tariffs__chips subhead-3 d-flex align-center text-no-wrap active"
                  @click="showFilters"
                >
                  {{ serviceChips }}
                  <img
                    loading="lazy"
                    src="@/assets/images/svg/chips-close_white.svg"
                    class="d-flex"
                    alt="close"
                    @click.stop="removeChips('service', [])"
                  >
                </div>
                <div
                  v-show="priceChips"
                  class="p-tariffs__chips subhead-3 d-flex align-center text-no-wrap active"
                  @click="showFilters"
                >
                  {{ priceChips }}
                  <img
                    loading="lazy"
                    src="@/assets/images/svg/chips-close_white.svg"
                    class="d-flex"
                    alt="close"
                    @click.stop="removeChips('price')"
                  >
                </div>
                <div
                  v-show="speedChips"
                  class="p-tariffs__chips subhead-3 d-flex align-center text-no-wrap active"
                  @click="showFilters"
                >
                  {{ speedChips }}
                  <img
                    loading="lazy"
                    src="@/assets/images/svg/chips-close_white.svg"
                    class="d-flex"
                    alt="close"
                    @click.stop="removeChips('speed')"
                  >
                </div>
              </div>
            </div>
          </template>

          <div
            itemscope
            itemtype="http://schema.org/ItemList"
            class="d-flex relative flex-wrap justify-between flex-column"
          >
            <template v-if="tariffsArr && tariffsArr.length">
              <template
                v-for="(tariff, index) in tariffsArr"
                :key="tariff.id"
              >
                <TariffsPageTariffsCard
                  v-show="index < (pseudoPage * 5)"
                  :provider="provider.provider"
                  :tariff="tariff"
                  @open-tariff-modal="openTariffModal"
                  @open-call-me="openCallMe"
                />
              </template>
            </template>

            <d-loader
              custom-class="tariffs-loader"
              :visible="loading"
            />
          </div>

          <d-btn
            v-if="tariffsArr?.filter((e, i) => i < (pseudoPage * 5))?.length < tariffsInfo?.totalItems"
            class="t-filters__more"
            :loading="loadingMore"
            @click="loadMore"
          >
            Показать еще
          </d-btn>
        </section>
        <template v-if="!disabledPortal">
          <LazyVMobileDrawer
            id="filter-drawer"
            v-model="mobileFilterDrawer"
            location="bottom"
            class="bottom"
            :class="{ 'btn-apply': showBtnApply }"
            :width="drawerWidth"
            :touchless="!mobileFilterDrawer"
            @update:model-value="hideFilters"
          >
            <div class="d-flex pointer t-filters__header">
              <button
                v-show="showClearTemperedBtn"
                class="secondary-dark--text subhead-3 t-filters__clear"
                @click="clearFiltersTempered"
              >
                Очистить
              </button>
              <p class="title-4">
                Фильтры
              </p>
              <div
                class="t-filters__close d-flex align-center justify-center"
                @click="hideFilters"
              >
                <img
                  loading="lazy"
                  src="@/assets/images/svg/close.svg"
                  alt="close"
                >
              </div>
            </div>
            <div class="t-filters__mobile">
              <LazyTariffsPageTariffsFilters
                v-if="agg"
                ref="tariffsFilters"
                v-model:filters="temperedFilters"
                :options="mobileAgg"
                :location-agg="locationAgg"
                :prices-range="pricesRange"
                :speeds-range="speedsRange"
                :btn-apply="showBtnApply"
                :range-active="temperedRanges"
                @get-preload-filters="getPreloadFilters"
                @search="mobileFilterTariffs"
              />
            </div>
            <div
              :class="{ show: showBtnApply }"
              class="t-filters__apply"
            >
              <button
                class="primary--bg"
                @click="applyMobile"
              >
                Применить
              </button>
            </div>
          </LazyVMobileDrawer>
        </template>
        <div
          v-else
          class="t-filters__se"
        >
          <LazyTariffsPageTariffsFilters
            v-if="agg"
            ref="tariffsFilters"
            :filters="filters"
            :options="agg"
            :show-clear-btn="showClearBtn"
            :location-agg="locationAgg"
            :prices-range="pricesRange"
            :speeds-range="speedsRange"
            :btn-apply="showBtnApply"
            :range-active="ranges"
            @clear-filters="clearFilters"
            @search="filterTariffs"
          />
        </div>
      </article>
      <LazyVDialog
        v-model="showDialogTariff"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalTariff
          v-if="showDialogTariff"
          :sel-tariff="selTariff"
          @close="showDialogTariff = false"
          @showtariffs="showDialogTariff = false"
          @house-changed="filterTariffs"
        />
      </LazyVDialog>
      <LazyVDialog
        v-if="!provider"
        v-model="showDialogCallMe"
        :fullscreen="!disabledPortal"
        :width="disabledPortal ? 'auto' : ''"
      >
        <LazyModalCallMeAutomatization
          v-if="!segmentationActive"
          :tariff="selTariff"
          @close="showDialogCallMe = false"
        />
        <LazyModalPartnersConsult
          v-else
          :tariff="selTariff"
          @close="showDialogCallMe = false"
        />
      </LazyVDialog>

      <LazyPartnersSegmentationsModals
        v-else-if="showDialogCallMe"
        :tariff="selTariff"
        @close="showDialogCallMe = false"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { tariffsWrapperComposible } from '@/composible/getTariffsWrapper'
import type { AggregatorTariffSelectionAnswersResult, FilterRange } from '~/api'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'

const props = defineProps({
  tariffs: {
    type: Object,
  },
  textAsync: {
    type: Object,
  },
  inCity: {
    type: Boolean,
  },
  userResponse: {
    type: Object as PropType<AggregatorTariffSelectionAnswersResult>,
  },
  filters: {},
  address: {},
  ranges: {},
  locationAgg: {},
  tariffsInHouse: Boolean,
})
const emit = defineEmits(['update:filters'])

const $gtm = useGtm()
const tariffsStore = useTariffs()
const cityStore = useCities()
const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)
const $route = useRoute()
const $router = useRouter()
const tariffsFilters = ref()
const drawerWidth = ref()
const ctx = useNuxtApp()
const provider = computed(() => mainStore.getDomainConfig)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const servicesFilters = computed(
  () => props.locationAgg?.availableServices || [],
)
const currentCity = computed(() => cityStore.getCity)
const houseUrl = computed(() => cityStore.getHouseUrl)
const page = ref(1)
const pageStart = ref(1)
const getAddressText = computed(() => cityStore.getAddressText)
const selTariff = ref()
const selProvider = ref()
const showDialogTariff = ref(false)
const showDialogCallMe = ref(false)

const sortDebug = computed(() =>
  tariffsArr.value?.sortDebug,
)
const openTariffModal = (tariff, provider) => {
  selTariff.value = tariff
  selProvider.value = provider
  showDialogTariff.value = true
}

const openCallMe = (tariff) => {
  $gtm.trackEvent({
    event: 'trackEvent',
    category: 'form',
    action: 'open',
    label: 'new-modal-tariffcard',
  })
  selTariff.value = tariff
  showDialogCallMe.value = true
}

const linkList = computed(() => tariffsStore.getLinkList)
const btns = computed(() => {
  const agg = (props.tariffs?.agg?.allAvailableServiceGroups || []).map(
    el => +el.id,
  )
  return linkList.value.filter(el => agg.includes(el.id))
})
const goTo = (link: string) => {
  $router.push({ path: link })
}

const showFilters = () => {
  openMobileFilters()
  mobileFilterDrawer.value = true
  if (window)
    window.document.getElementsByTagName('html')[0]?.classList.add('hidden')
  nextTick(() => {
    const target = window.document.getElementById('filter-drawer')?.clientHeight
    drawerWidth.value = target
  })
}

const showDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'tariffs-list' })
}

const mobileAgg = computed(() => preloadFilters.value?.agg || agg.value)

const tags = ref([
  {
    name: 'Акции %',
    value: 'hasPromo',
    filter: 'promo',
  },
  {
    name: 'Игровые',
    value: 'hasGaming',
    filter: 'gaming',
  },
  {
    name: 'Бесплатное подключение',
    value: 'hasFreeConnection',
    filter: 'freeConnection',
  },
])
const availableTags = computed(() =>
  tags.value.filter(el => agg.value[el.value]),
)

const serviceChips = computed(() => {
  const selected = []

  if (props.filters?.serviceSets?.length)
    selected.push(
      ...servicesFilters.value
        .filter(el => props.filters.serviceSets.includes(el.id))
        .map(el => el.name.replace('Мобильная связь', 'Моб. связь')),
    )
  if (props.filters?.serviceSetsContains?.length)
    selected.push(
      ...servicesFilters.value
        .filter(el => props.filters.serviceSetsContains.includes(el.id))
        .map(el => el.name.replace('Мобильная связь', 'Моб. связь')),
    )

  if (selected.length > 3) return 'Выбрано услуг: ' + selected.length
  else if (selected.length) return selected.join(', ')
  else return ''
})

const toggleChips = (val: string) => {
  page.value = 1
  pageStart.value = 1
  props.filters[val] = !props.filters[val]
  filterTariffs()
}
const removeChips = (target: string, val: any) => {
  if (target === 'price') {
    props.filters.priceMin = 0
    props.filters.priceMax = 0
    props.ranges.priceMax = false
    props.ranges.priceMin = false
  }
  else if (target === 'speed') {
    props.filters.speedMin = 0
    props.filters.speedMax = 0
    props.ranges.speedMin = false
    props.ranges.speedMax = false
  }
  else if (target === 'service') {
    props.filters.serviceSets = []
    props.filters.serviceSetsContains = []
    props.filters.smartFilter = undefined
  }
  else props.filters[target] = val
  filterTariffs()
}
const priceChips = computed(() => {
  return props.ranges.priceMin && props.ranges.priceMax
    ? `${props.filters.priceMin}-${props.filters.priceMax} ₽/мес`
    : props.ranges.priceMin
      ? `от ${props.filters.priceMin} ₽/мес`
      : props.ranges.priceMax
        ? `до ${props.filters.priceMax} ₽/мес`
        : ''
})
const speedChips = computed(() => {
  return props.ranges.speedMin && props.ranges.speedMax
    ? `${props.filters.speedMin}-${props.filters.speedMax} Мбит/с`
    : props.ranges.speedMin
      ? `от ${props.filters.speedMin} Мбит/с`
      : props.ranges.speedMax
        ? `до ${props.filters.speedMax} Мбит/с`
        : ''
})

const pricesRange = computed<NonNullable<FilterRange>>(() => {
  return (
    agg.value?.priceRange ?? {
      min: 0,
      max: 0,
    }
  )
})
const speedsRange = computed<NonNullable<FilterRange>>(() => {
  const speed = agg.value?.speedRange ?? {
    min: 0,
    max: 0,
  }
  return speed.max === null
    ? {
        min: 0,
        max: 0,
      }
    : speed
})

const getActive = () => {
  if (import.meta.client)
    setTimeout(() => {
      const parent = (
        document.getElementsByClassName(
          'p-tariffs__sf-wrap',
        )
      )?.[0]
      if (parent) {
        const target = parent.getElementsByClassName(
          'active',
        )
        if (target[0]) {
          const rect = target[0].getBoundingClientRect()
          const elW = rect.width
          const elX = rect.x
          const windowW = window.innerWidth
          if (windowW - elW < elX)
            parent.scrollTo({ left: elX - (windowW - elW) })
        }
      }
    }, 10)
}

const serviceScroll = (val) => {
  const parent = document.getElementById('service-parent')
  const activeEl = document.getElementById(
    'service' + props.filters.serviceSets!.join(''),
  )
  if (activeEl && parent && !val) {
    parent.scrollTo({
      left: activeEl.offsetLeft + 16 - window.innerWidth + activeEl.offsetWidth,
      top: 0,
      behavior: 'smooth',
    })
  }
  else if (activeEl && parent && val) {
    parent.scrollTo({
      left:
        activeEl.offsetLeft - window.innerWidth / 2 + activeEl.offsetWidth / 2,
      top: 0,
      behavior: 'smooth',
    })
  }
}

onBeforeMount(() => {
  const query = $route.query
  page.value = query && query.page ? +query.page : 1
  pageStart.value = query && query.page ? +query.page : 1
})

onMounted(() => {
  serviceScroll(false)

  setTimeout(() => {
    getActive()
  }, 100)
})
watch(
  () => houseUrl.value,
  () => {
    if ($route.path.toString().includes(currentCity.value?.url)) filterTariffs()
  },
)

const {
  temperedFilters,
  temperedRanges,
  showBtnApply,
  mobileFilterDrawer,
  loadingMore,
  loading,
  tariffsArr,
  tariffsInfo,
  pseudoPage,
  agg,
  openMobileFilters,
  sortList,
  currentSort,
  setSort,
  filterTariffs,
  mobileFilterTariffs,
  applyMobile,
  clearFilters,
  clearFiltersTempered,
  loadMore,
  showClearBtn,
  showClearTemperedBtn,
  hideFilters,
  preloadFilters,
  getPreloadFilters,
} = tariffsWrapperComposible(props, emit, {
  strictTechs: true,
  mode: 'PARTN',
}, {
  providerSlugs: provider.value?.provider.slug,
})
</script>

<style scoped lang="scss">
.p-tariffs {
  background-color: color(gray-p);
  padding-top: 24px;
  padding-bottom: 32px;

  &__title {
    margin-top: 24px;
    margin-bottom: 4px;
  }
  &__secondtitle {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    margin-bottom: 24px;
  }

  .t-filters__more {
    color: var(--form_btn);
  }

  .small-drawer {
    &__chbx {
      min-width: 160px;
      height: 40px;
      margin-right: 8px;

      &-fake {
        min-width: 160px;
        height: 0;
        flex: 1 1 160px;
        margin-right: 8px;
      }
    }
  }

  &__sf,
  &__smart-filters {
    margin-bottom: 24px;
    max-width: calc(100vw - 32px);
    @media (max-width: getBreakpoint(desktop)) {
      margin-bottom: 16px;
    }

    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 16px;
      max-width: calc(100vw - 16px);
    }

    &:deep(a),
    &:deep(button) {
      flex-grow: 0;
      width: unset;
      margin-right: 4px;
      margin-bottom: 4px;
      background-color: white;
      border-radius: 32px;
      font-size: 14px;
      line-height: 20px;
      padding: 7px 11px;
      color: color(black);

      &.active {
        background-color: var(--mainColor);
        color: var(--font_main);
        border: 1px solid transparent;
      }
    }

    &-wrap {
      height: 36px;
      @media (max-width: getBreakpoint(tablet)) {
        scrollbar-width: none;
        max-width: 100vw;
        height: 42px;
        overflow-x: auto;
        overflow-y: hidden;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }
    }
  }

  &__smart-filters {
    &:deep(.service-btns) {
      max-width: 100vw;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__address {
    margin-bottom: 16px;
    &-btn {
      cursor: pointer;
      color: color(secondary-dark);
      transition: color 0.24s;
      margin-right: 4px;
      position: relative;
      padding-left: 28px;

      .d-icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        color: color(primary);
      }
    }
  }

  &__recommend {
    margin-bottom: 12px;
    position: relative;
    padding-left: 24px;
    font-size: fontSize(title);
    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(paragraph);
    }
    .d-icon {
      position: absolute;
      left: 0;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__chips {
    padding: 4px 12px;
    height: 32px;
    background-color: transparent;
    border-radius: 28px;
    border: 1px solid color(gray-light);
    font-size: fontSize(paragraph);
    color: color(gray-dark);
    transition-property: color, border;
    margin-right: 4px;
    flex-shrink: 0;

    img {
      margin-left: 8px;
      width: 20px;
      height: 20px;
    }
    &.active {
      transition-delay: 0.2s;
      border: 1px solid var(--mainColor);
      background-color: var(--mainColor);
      color: var(--font_btn);
      padding: 4px 4px 4px 12px;
    }
    &-promo {
      &.active {
        transition-delay: 0.2s;
        border: 1px solid transparent;
        background: color(gradient);
        color: color(white);
      }
    }

    &-wrap {
      margin-bottom: 16px;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0px;
      }
    }
  }

  &__menu {
    margin-left: auto;
    @media (max-width: getBreakpoint(desktop)) {
      margin-left: 0;
      margin-right: auto;
    }
  }
  &__mobile-filter {
    margin-bottom: 16px;
  }

  &__all-filters {
    img {
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }

    &-count {
      background-color: color(primary);
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 6px 1px auto;
    }

    &:after {
      content: "";
      position: absolute;
      left: -16px;
      top: -16px;
      right: -16px;
      bottom: -16px;
    }
  }
}

.tariffs-loader {
  background: rgba(color(gray-p), 0.75) !important;
}
</style>
