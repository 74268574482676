<template>
  <div
    class="p-hat"
    :class="{
      'p-hat--white': true,
      'p-hat--form': showForm,
      'p-hat--short': houseUrl,
    }"
  >
    <div class="p-hat__wrap container relative">
      <d-breadcrumbs
        :items="crumbsItems"
        :dark="true"
      />
      <div class="d-flex align-center">
        <div class="d-flex flex-column">
          <h1
            class="p-hat__h1"
            :class="{ 'full-width': houseUrl || !showForm }"
            v-html="
              houseUrl
                ? `Подключение\nдомашнего интернета от ${provider.provider.name}`
                : h1
            "
          />
          <p
            v-if="h2"
            class="p-hat__h2"
            v-html="h2"
          />
        </div>
        <div
          v-if="!houseUrl && showForm && provider.provider?.icon_large"
          class="p-hat__img relative"
        >
          <img
            loading="lazy"
            :src="
              imgUrl(
                provider.provider?.icon_large,
                disabledMobile ? 800 : 40,
                0,
              )
            "
            alt="img-large"
          >
        </div>
      </div>
      <div
        v-if="!houseUrl && showForm"
        class="p-hat__form d-flex"
      >
        <div class="p-hat__form-wrap">
          <p class="p-hat__form-title fwm">
            Узнайте все акции и специальные тарифы от
            {{ provider.provider.name }} в вашем доме
          </p>
          <button
            data-test="city-change-find-out-form"
            class="p-hat__form-city d-flex align-center"
            @click="showDialogCity"
          >
            <span class="d-flex p-hat__form-city-icon" /><template v-if="getAddressText.cityOnly">
              {{
                getAddressText.cityOnly
              }}
            </template>
          </button>
          <div
            data-test="search-by-address-find-out-form"
            class="p-hat__form-input d-flex align-center"
            @click="showModalAddress"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/pin_large-bl.svg"
              alt="pin"
            >
            <p>Введите улицу и дом</p>
            <button>
              <span>Найти тарифы</span>
              <img
                loading="lazy"
                src="@/assets/images/svg/search-thin_w.svg"
                alt="pin"
              >
            </button>
          </div>
        </div>
        <div
          class="p-hat__form-cb d-flex flex-column"
        >
          <p>
            или закажите звонок, наш специалист перезвонит
            {{ !btn60Show ? 'в течение 10 минут' : showNow ? "в течение 60 секунд" : "в удобное время" }}
          </p>
          <button
            data-test="order-call-button"
            class="p-hat__form-btn d-flex align-center additional-light--text title-5"
            @click="showDialogCallPartner"
          >
            <span
              class="mask-icon mask-size-lg mask-phone-thin"
              style="margin-right: 4px;"
            />Заказать звонок
          </button>
        </div>
      </div>
      <div
        v-else-if="
          houseUrl
            && providersIds
            && provider
            && (providersIds.includes(provider.provider.id) || providerConsumed?.some(el => providersIds.includes(+el)) || !providersIds.length)
        "
        class="p-hat__availability d-flex"
      >
        <div class="p-hat__availability-wrap">
          <p
            v-if="(providersIds.includes(provider.provider.id)) || providerConsumed?.some(el => providersIds.includes(+el))"
            class="p-hat__availability-title fwm"
          >
            <span class="p-hat__availability-ico">
              <span
                class="additional-light--bg white--text d-flex"
                style="border-radius: 50%"
              ><span class="mask-icon mask-ischecked mask-size-lg" /></span>
            </span>Есть возможность подключения по адресу
          </p>
          <p
            v-else
            class="p-hat__availability-title fwm"
          >
            <span class="p-hat__availability-ico">
              <img
                loading="lazy"
                src="@/assets/images/svg/cancel_red.svg"
                alt="cancel"
              >
            </span>Отсутствует техническая возможность подключения
          </p>
          <p class="p-hat__availability-address d-flex align-center">
            {{ getAddressText.fullAddress }}
            <button
              class="d-inline-flex p-hat__availability-address-edit"
              @click="showModalAddress"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/pencil.svg"
                alt="cancel"
              >
            </button>
          </p>
          <p
            v-if="!providersIds.length"
            class="p-hat__availability-change"
          >
            Попробуйте <button @click="showModalAddress">
              изменить адрес
            </button>
          </p>
        </div>
        <ClientOnly>
          <button
            v-if="providersIds.length && $route.path === '/'"
            class="p-hat__availability-goto d-flex align-center"
            @click="$router.push({ path: `/${currentCity?.url}/` })"
          >
            Смотреть тарифы
          </button>
          <button
            v-else-if="providersIds.length && !segmentationActive && (disabledPortal || showGreenBtn)"
            class="p-hat__availability-btn d-flex align-center title-5"
            :class="{ 'additional-light--bg white--text': showGreenBtn, 'additional-light--text': !showGreenBtn }"
            @click="showDialogCall"
          >
            <span class="mask-icon mask-size-lg mask-phone-thin" />Заказать звонок
          </button>
        </ClientOnly>
      </div>
      <div
        v-else-if="houseUrl && providersIds && provider"
        data-test="auto-check-failed-form"
        class="p-hat__notavailability"
      >
        <p class="p-hat__availability-title fwm">
          <span class="p-hat__availability-ico">
            <img
              loading="lazy"
              src="@/assets/images/svg/cancel_red.svg"
              alt="cancel"
            >
          </span>Автоматическая проверка не удалась
        </p>
        <p
          class="p-hat__availability-address gray-dark--text d-flex align-center hidden"
        >
          <span class="text-no-wrap">{{ getAddressText.fullAddress }}</span>
          <button
            class="d-inline-flex p-hat__availability__address-edit"
            @click="showModalAddress"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/pencil.svg"
              alt="cancel"
            >
          </button>
        </p>
        <p class="p-hat__availability-subtitle">
          Попробуйте
          <span
            class="secondary-dark--text"
            @click="showModalAddress"
          >изменить адрес</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { imgUrl } from '~/helpers'

defineProps({
  h1: {},
  h2: {},
  providersIds: {},
  providerConsumed: {},
  crumbsItems: {},
  showForm: {
    type: Boolean,
    default: true,
  },
})

const ctx = useNuxtApp()
const mainStore = useMainStore()
const cityStore = useCities()
const disabledMobile = computed(() => mainStore.disabledMobile)
const disabledPortal = computed(() => mainStore.disabledPortal)
const houseUrl = computed(() => cityStore.getHouseUrl)
const currentCity = computed(() => cityStore.getCity)
const btn60Show = computed(() => mainStore.getBtn60Show)
const showNow = computed(() => mainStore.getCallSlots?.display_right_away)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const showGreenBtn = computed(() => mainStore.getShowGreenBtn)

const getAddressText = computed(() => cityStore.getAddressText)

const provider = computed(() => mainStore.getDomainConfig)
const domain = computed(() => mainStore.getDomain)

const showModalAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'offer' })
}
const showDialogCity = () => {
  ctx.$event('cityDialog', { redirect: true, label: 'offer' })
}
const showDialogCall = () => {
  ctx.$event('callDialog', 'Заказать звонок')
}
const showDialogCallPartner = () => {
  ctx.$event('callDialogPartner', true)
}
</script>

<style scoped lang="scss">
.p-hat {
  padding-top: 0;
  padding-bottom: 0;

  .mask-phone-thin {
    mask-image: url('@/assets/images/svg/phone-thin.svg');
  }
  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  &__wrap {
    z-index: 2;
  }

  &--form {
    margin-bottom: 104px;

    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 200px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 196px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 168px;
    }

    //.p-hat__wrap {
    //  min-height: 621px;
    //  display: flex;
    //  flex-direction: column;
    //}
    //.p-hat__h1 {
    //  margin-top: auto;
    //  margin-bottom: auto;
    //}
  }
  &--short {
    margin-bottom: 64px;
  }

  &__h1 {
    font-size: 48px;
    line-height: 1.4;
    color: var(--font_offer);
    margin-top: 40px;
    margin-bottom: 56px;
    max-width: 600px;
    white-space: pre-wrap;
    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(headline-5);
      margin-top: 12px;
      margin-bottom: 24px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 23px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(headline-6);
      margin-top: 4px;
      margin-bottom: 20px;
    }

    &.full-width {
      max-width: unset;
      margin-bottom: 16px;
    }
  }

  &__h2 {
    color: var(--font_offer);
    font-size: fontSize(headline-8);
    line-height: 1.4;
    margin-bottom: 32px;
    white-space: pre-wrap;
    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(title);
      line-height: 1.5;
      margin-bottom: 24px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 15px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(paragraph);
    }
  }

  &__img {
    flex-grow: 1;
    min-height: 320px;
    img {
      position: absolute;
      bottom: 0;
      max-width: 756px;
      max-height: 328px;
      object-fit: contain;
      object-position: bottom;
      right: 220px;
      transform: translateX(50%);
    }
    @media (max-width: getBreakpoint(desktop)) {
      display: none;
    }
  }
  &__form {
    padding: 40px;
    background: var(--bg_form);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-bottom: -104px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 24px 16px;
      margin-bottom: -200px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: -196px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: -168px;
      padding: 20px 16px;
    }

    &-wrap {
      flex-grow: 1;
      @media (max-width: getBreakpoint(desktop)) {
        margin-bottom: 32px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 16px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 12px;
      }
    }

    &-title {
      font-size: fontSize(headline-5);
      line-height: 1.4;
      margin-bottom: 32px;
      max-width: 590px;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(headline-8);
        margin-bottom: 12px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 17px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 15px;
      }
    }

    &-city {
      margin-bottom: 16px;
      transition: color 0.24s;
      &-icon {
        margin-right: 4px;
        width: 24px;
        height: 24px;
        mask-image: url('@/assets/images/svg/location.svg');
        background-color: currentColor;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 24px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 12px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: fontSize(paragraph);
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(label);
      }

      &:hover {
        color: color(primary);
      }
    }

    &-input {
      margin-right: 40px;
      height: 74px;
      border-radius: 40px;
      background-color: color(white);
      border: 1px solid color(gray-dark);
      padding-left: 20px;
      cursor: pointer;
      & > img {
        margin-right: 4px;
        width: 36px;
        height: 36px;
        @media (max-width: getBreakpoint(tablet)) {
          width: 32px;
          height: 32px;
        }
      }
      p {
        color: color(gray-dark);
      }
      button {
        margin-left: auto;
        height: 58px;
        background: var(--form_btn);
        margin-right: 8px;
        border-radius: 40px;
        padding: 16px 32px;
        font-size: fontSize(headline-8);
        font-weight: 500;
        color: var(--font_btn);
        img {
          display: none;
        }
        @media (max-width: getBreakpoint(tablet)) {
          padding: 0;
          margin-right: 4px;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            display: none;
          }
          img {
            margin-right: 0;
            display: flex;
            width: 32px;
            height: 32px;
          }
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 40px;
          height: 40px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      @media (max-width: getBreakpoint(tablet)) {
        height: 56px;
        margin-right: 0;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 48px;
        padding-left: 16px;
        p {
          font-size: fontSize(paragraph);
        }
      }
    }

    &-cb {
      width: 360px;
      padding-left: 44px;
      justify-content: flex-end;
      align-items: flex-start;
      border-left: 1px solid #ededed;
      p {
        color: color(gray-dark);
        margin-bottom: 24px;
      }
      @media (max-width: getBreakpoint(desktop)) {
        width: 100%;
        padding-left: 0;
        border-left: none;
      }
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(paragraph);
        p {
          margin-bottom: 12px;
        }
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        p {
          font-size: 13px;
        }
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        p {
          font-size: fontSize(label);
        }
      }
    }
    &-btn {
      border: 1px solid color(additional-light);
      height: 56px;
      border-radius: 40px;
      padding: 8px 16px;
      color: color(additional-light);
      @media (max-width: getBreakpoint(tablet)) {
        height: 48px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
      }
    }
    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
    }
  }

  &__availability {
    background-color: white;
    border: 1px solid color(gray-darker);
    border-radius: 16px;
    padding: 32px 40px 32px 72px;
    margin-bottom: -64px;

    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 24px 16px 24px 48px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 20px 16px 20px 48px;
    }
    &-btn {
      margin-left: auto;
      border: 1px solid color(additional-light);
      height: 56px;
      border-radius: 40px;
      padding: 8px 16px;
      @media (max-width: getBreakpoint(desktop)) {
        height: 48px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-left: 0;
        margin-right: auto;
        margin-top: 16px;
        padding: 4px 12px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 46px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 45px;
      }
      .mask-icon {
        margin-right: 8px;
      }
    }
    &-goto {
      margin-left: auto;
      height: 56px;
      border-radius: 40px;
      padding: 8px 40px;
      background: var(--form_btn);
      color: var(--font_btn);
      @media (max-width: getBreakpoint(desktop)) {
        margin-left: 0;
        margin-top: 16px;
        margin-right: auto;
        height: 48px;
        justify-content: center;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-right: 0;
        margin-left: -32px;
        max-width: 400px;
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 42px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
      }
    }

    &-ico {
      position: absolute;
      left: -32px;
      top: 2px;
      img, & > span {
        width: 24px;
        height: 24px;
      }
    }

    &-title {
      font-size: fontSize(headline-5);
      line-height: 1.4;
      margin-bottom: 4px;
      position: relative;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(headline-8);
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(title);
        line-height: 1.5;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(paragraph);
      }
    }
    &-subtitle {
      margin-top: 8px;
      font-size: fontSize(headline-8);
      line-height: 1.4;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(title);
        line-height: 1.5;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 15px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(paragraph);
      }
      .secondary-dark--text {
        cursor: pointer;
        transition: color 0.24s;
        &:hover {
          color: var(--form_btn);
        }
      }
    }
    &-address {
      font-size: fontSize(headline-8);
      line-height: 1.4;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 24px);
      }
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(title);
        line-height: 1.5;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 15px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(paragraph);
      }
      &-edit {
        img {
          width: 24px;
          height: 24px;
          margin-top: -3px;
          margin-left: 4px;
        }
      }
    }
    &-change {
      color: color(gray-dark);
      margin-top: 12px;
      button {
        color: color(secondary-dark);
        transition: color 0.24s;
        &:hover {
          color: color(primary);
        }
      }
    }
  }

  &__unform {
    margin-top: 16px;
    width: 100%;
  }
  &__inputs {
    & > div {
      max-width: 269px;
      margin-right: 16px;
      margin-bottom: 20px;
    }
    & > p {
      align-self: center;
      margin-bottom: 20px;
    }

    @media (max-width: getBreakpoint(desktop)) {
      flex-wrap: wrap;
    }

    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
      & > div {
        max-width: unset;
      }
      & > p {
        margin-bottom: 0;
        order: 2;
      }
    }
  }

  &__input {
    margin-bottom: 20px;
    background: color(gray-p);

    &:deep(.el-input__wrapper) {
      width: 270px;
      height: 56px;
      input {
        height: 56px;
        line-height: 56px;
      }
    }

    @media (max-width: getBreakpoint(tablet)) {
      &:deep(.el-input__wrapper) {
        width: 100%;
      }
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      &:deep(.el-input__wrapper) {
        height: 44px !important;
        input {
          height: 44px !important;
          line-height: 44px !important;
        }
      }
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      &:deep(.el-input__wrapper) {
        height: 40px !important;

        input {
          height: 40px !important;
          line-height: 40px !important;
        }
      }
    }
  }

  &__submit {
    height: 56px;
    max-width: 270px;
    width: 269px;
    margin-right: 16px;
    font-weight: 500;
    background: var(--form_btn);
    color: color(white);
    &:disabled,
    :active {
      background: var(--form_btn);
    }
    @media (max-width: getBreakpoint(tablet)) {
      max-width: 100%;
      width: 400px;
      margin-bottom: 24px;
      order: 2;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 44px !important;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px !important;
    }
  }

  &__actions {
    justify-content: space-between;
    align-items: center;
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column-reverse;
      .d-block {
        text-align: center;
      }
    }
  }

  &__notavailability {
    background-color: white;
    border: 1px solid color(gray-darker);
    border-radius: 16px;
    padding: 32px 40px 32px 68px;
    margin-bottom: -64px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 24px 24px 24px 52px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 24px 16px 24px 44px;
    }
  }
  &--white {
    //&:before {
    //  content: '';
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  height: 136px;
    //  background-color: color(white);
    //}
  }
}
</style>
